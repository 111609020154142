import { useState, useEffect } from 'react'
import { lang,logout_url } from '../lang'
import styles from '../../assets/css/menu.module.css';
import cstyles from '../../assets/css/calendar.module.css';

export default function Menu(props) {
    const { setMenu, menu, right, className} = props;
    const email = localStorage.getItem("email");

    const handleClick = (new_menu) =>{
        if(new_menu==="logout"){
            localStorage.removeItem("email");
            localStorage.removeItem("cmu_account");
            localStorage.removeItem("token");
            localStorage.removeItem("login_time");
            // new_menu = "calendar";
            // window.location = process.env.PUBLIC_URL + "/rsvn"
            window.location = logout_url
        }
        setMenu(new_menu);
    }

    return (
        <div className={className+` ${(right===true)?cstyles["menu"]+' '+styles["right"]:cstyles["menu"]}`}>
            <div className={styles["booking-btn"]+' '+styles["menu-section"]+' '+cstyles["booking-btn"]}>
                {(!email)?(
                    <button onClick={e=>handleClick("login")} className={(menu==="login")?styles["active"]:''}><i className="fa-solid fa-calendar-days"></i>{lang('จองห้อง','Booking')}</button>
                ):(
                    <button onClick={e=>handleClick("create")} className={(menu==="create")?styles["active"]:''}><i className="fa-solid fa-calendar-days"></i>{lang('จองห้อง','Booking')}</button>
                )}
            </div>
            <div className={styles["category"]+' '+styles["menu-section"]}>
                <h4>{lang('หมวดหมู่','Categories')}</h4>
                <ul>
                    <li onClick={e=>handleClick("calendar")} className={(menu==="calendar")?styles["active"]:''}>{lang('ปฏิทินการจอง','Booking Calendar')}</li>
                    <li onClick={e=>handleClick("table")} className={(menu==="table")?styles["active"]:''}>{lang('สรุปการจองรายวัน','Daily Booking')}</li>
                    {(email)?(
                    <li onClick={e=>handleClick("mybooking")} className={(menu==="mybooking")?styles["active"]:''}>{lang('การจองของฉัน','My Booking')}</li>
                    ):''}
                    {(email)?(
                    <li onClick={e=>handleClick("setting")} className={(menu==="setting")?styles["active"]:''}>{lang('ข้อมูลของฉัน','My Profile')}</li>
                    ):''}
                    {(email)?(
                    <li onClick={e=>handleClick("logout")} className={(menu==="logout")?styles["active"]:''}>{lang('ออกจากระบบ','Logout')}</li>
                    ):''}
                </ul>
            </div>
        </div>
    )
}
