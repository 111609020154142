import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import axios from 'axios';
import api from '../api';
import { login_url, lang, logout_url } from '../lang';

export default function Callback(props) {
    const {styles} = props;
    let [error, setError] = useState('');
    let [showError, setShowError] = useState(false);
    let [searchParams, setSearchParams] = useSearchParams();
    let [info, setInfo] = useState('');
    let [timer, setTimer] = useState(5);
    let [showInfo, setShowInfo] = useState(false);
    let redirectpage = login_url;

    const fetchInit = async (code) =>{
        try {
            const ip_response = await axios.get('https://api.ipify.org/?format=json');
            // setIP(res.data.IPv4);
            const response = await api.get(`checkcallback?access_token=${code}`)
            if(response.data.status==="ok" && response.data.Message !=="Unauthorized"){
                const cmu_account = response.data.cmu_account;
                // check have user or not
                const admin_response = await api.get(`rsvn_user?email=${response.data.cmuitaccount}`)
                if(admin_response.data.status==="ok"){
                    localStorage.setItem('email',cmu_account.cmuitaccount);
                    localStorage.setItem('ip',ip_response.data.ip);
                    localStorage.setItem('cmu_account',JSON.stringify(cmu_account));
                    localStorage.setItem('token',response.data.access_token);
                    localStorage.setItem('login_time',new Date().toISOString());
                    // create user
                    if(admin_response.data.results===null||admin_response.data.results.length===0){
                        const jsonData = {
                            email: cmu_account.cmuitaccount,
                            name: cmu_account.prename_TH+' '+cmu_account.firstname_TH+' '+cmu_account.lastname_TH,
                            faculty: cmu_account.organization_name_TH,
                            password: '',
                            phone: '',
                            user_info: JSON.stringify(cmu_account),
                            active: 1,
                            user: JSON.stringify(cmu_account),
                            ip: ip_response.data.ip,
                        } 
                        api.post(`rsvn_user/create`,jsonData)
                        .then(response => {
                            if(response.data.status==='ok'){
                                setShowInfo(true);
                                setInfo('เพิ่มผู้ใช้ใหม่');
                            }else{
                                setShowError(true);
                                let message = JSON.stringify(response.data.message);
                                setError(message);
                            }
                        })
                        .catch(error => {
                            setShowError(true);
                            setError(error);
                        });
                    }
                    // update user session
                    let data = {
                        email: response.data.cmuitaccount,
                        ip: ip_response.data.ip,
                        token: code,
                        user: cmu_account,
                        cmu_account: cmu_account,
                    }
                    const admin_session = await api.post(`rsvn_user/session`,data);
                    if(admin_session.data.status==="ok"){
                        setShowInfo(true);
                        setInfo('Login Success');
                    }else{
                        setShowError(true);
                        setError(admin_session.data.message);
                    }
                }else{
                    setShowError(true);
                    setError(admin_response.data.message);
                }
            }else{
                setShowError(true);
                let message = JSON.stringify(response.data.Message);
                setError(message);
            }

        } catch (err) {
            if(err.response){
                //Not in 202 response range
                console.log(err.response.data);
                console.log(err.response.headers);
                console.log(err.response.status);
            } else {
                console.log(`Error: ${err.message}`);
            }
        }
    }

    useEffect(() => {
        const get_code =searchParams.get("code");
        // request access token 
        if(get_code===null){
            window.location = process.env.PUBLIC_URL + '/rsvn?menu=login';
        }else{
            fetchInit(get_code);
        }
    },[]);

    useEffect(() => {
        if(!showError){
            let cerror = searchParams.get("error");
            let error_description = searchParams.get("error_description");
            if(cerror===null&&error_description==null){
                if (timer > 0) {
                    setTimeout(() => setTimer(timer - 1), 1000);
                } else {
                    clearTimeout();
                    setTimer('0');
                    window.location = process.env.PUBLIC_URL +'/rsvn';
                }
            }else{
                setShowError(true);
                setError(error_description);
            }
        }
    });

    const reLogin = (e) => {
        e.preventDefault();
        localStorage.removeItem("email");
        localStorage.removeItem("cmu_account");
        localStorage.removeItem("token");
        localStorage.removeItem("login_time");
        // window.location = process.env.PUBLIC_URL + "/rsvn?menu=login";
        window.location = logout_url+"?menu=login";
    }

    return (
        <div className="login">
            <h3>{lang("กำลังเข้าสู่ระบบ กรุณารอสักครู่","Login progress..")}</h3>
            {(showError)?(
            <>
                <div className="alert alert-danger">
                    <p>
                        <b>Error:</b> {error}
                    </p>
                </div>
                <fieldset>
                    <p className="register">
                        <i className="fa-solid fa-key"></i> 
                        Please&nbsp;
                        <a href={process.env.PUBLIC_URL + "/authen"} className="a-links">re-login</a> 
                        &nbsp;for authen you account&nbsp;
                    </p>
                </fieldset>
            </>
            ):(
                <>
                    {(showInfo)?(
                        <div className="alert alert-info">
                            <p className="register">
                                <b>{info}:</b><br /> You should be automatically redirected in <span className="text-red-400">{timer}</span> seconds.
                            </p>
                        </div>
                    ):(
                        <div className="alert alert-info">
                            <p className="register">
                                <b>Successful login:</b><br /> You should be automatically redirected in <span className="text-red-400">{timer}</span> seconds.
                            </p>
                        </div>
                    )}
                    
                    {(showInfo)?(
                    <p className="mb-3 float-left">
                            <a href={process.env.PUBLIC_URL + "/rsvn?menu=create"} className="a-links red"> {lang('จองห้อง','Click here')}</a>&nbsp; {lang('','to create booking')}
                    </p>
                    ):''}
                    {(showInfo)?(
                    <p className="float-right"> 
                        <i className="fa-solid fa-key"></i>
                            {lang('หากไม่ใช่ท่าน กรุณา','If not you please')}&nbsp; <div onClick={e=>reLogin(e)} className="a-links">{lang('เข้าสู่ระบบ','re-login')}</div> 
                    </p>
                    ):''}
                    
                </>
            )}
        </div>
    )
}
